// src/components/ProfileTab.js
import React, { useState, useEffect, useContext } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { AuthContext } from '../contexts/AuthContext';
import { connectSSE, sendTabChange } from '../services/api';
import { renderWidget } from '../utils/WidgetRenderer';

const ProfileTab = () => {
    const token = useContext(AuthContext);

    // Флаг, что идёт загрузка (пока не придёт первый список виджетов)
    const [isLoading, setIsLoading] = useState(true);

    // Массив «сырых» виджетов, которые приходят с бэкенда
    const [widgets, setWidgets] = useState([]);

    useEffect(() => {
        if (!token) return;

        // Сообщаем бэкенду, что мы на вкладке 'profile'
        sendTabChange(token, 'profile');
        setIsLoading(true);

        // Функция добавления/обновления виджета по ключу
        // upsertWidget
        const upsertWidget = (widgetKey, dataObj) => {
            setWidgets((prev) => {
                const copy = [...prev];
                const idx = copy.findIndex((w) => w.k === widgetKey);

                if (idx === -1) {
                    copy.push({
                        // вместо "key" используем "k", как в GuildTab и на бэкенде
                        k: widgetKey,
                        type: dataObj.type || 'unknown',
                        value: { ...dataObj },
                    });
                } else {
                    const updated = { ...copy[idx] };
                    updated.type = dataObj.type || updated.type;
                    updated.value = {
                        ...updated.value,
                        ...dataObj,
                    };
                    copy[idx] = updated;
                }
                return copy;
            });
        };

// updateWidgetContent
        const updateWidgetContent = (widgetKey, newValue) => {
            setWidgets((prevWidgets) => {
                const idx = prevWidgets.findIndex((w) => w.k === widgetKey);
                if (idx === -1) return prevWidgets;
                const copy = [...prevWidgets];
                copy[idx] = {
                    ...copy[idx],
                    value: {
                        ...copy[idx].value,
                        ...newValue,
                    },
                };
                return copy;
            });
        };

        // SSE handlers (аналогично GuildTab.js)
        const updateHandlers = {
            // Полный «пакет» виджетов
            widgetsInit: (rawValue) => {
                try {
                    const arr = JSON.parse(rawValue);
                    setWidgets(arr);
                    setIsLoading(false);
                } catch (e) {
                //    console.error('Ошибка парса widgetsInit:', e);
                }
            },

            // Частичное обновление конкретного виджета
            widgetUpdate: (rawValue) => {
                try {
                    const obj = JSON.parse(rawValue);
                    const { key, value } = obj;
                    if (key && value) {
                        updateWidgetContent(key, value);
                    }
                } catch (e) {
                //    console.error('Ошибка обработки widgetUpdate:', e);
                }
            },

            // Динамическая вставка/обновление виджета (через ключ)
            _widgetDynamic: (widgetKey, rawValue) => {
                try {
                    const dataObj = typeof rawValue === 'string'
                        ? JSON.parse(rawValue)
                        : rawValue;
                    upsertWidget(widgetKey, dataObj);
                    setIsLoading(false);
                } catch (e) {
                  //  console.error(`Ошибка парса для виджета ${widgetKey}:`, e);
                }
            },
        };

        const eventSource = connectSSE(
            token,
            updateHandlers,
            (err) => {
               // console.error('Ошибка SSE-соединения:', err);
            },
            'profile'
        );

        return () => {
            eventSource.close();
        };
    }, [token]);

    // 1) Если нет авторизации
    if (!token) {
        return (
            <Card sx={{ p: 2 }}>
                <Typography>Not authorized</Typography>
            </Card>
        );
    }

    // 2) Если всё ещё идёт загрузка (пока не пришёл первый список виджетов)
    if (isLoading) {
        return (
            <Card sx={{ p: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 40 }} />
                <Skeleton variant="rectangular" height={100} />
            </Card>
        );
    }

    // 3) Рендер
    return (
        <Container maxWidth="md" sx={{ py: 2, px: { xs: 1, sm: 2, md: 3 } }}>

            {widgets.map((widget) => (
                <Box key={widget.k}>
                    {renderWidget(widget)}
                </Box>
            ))}
        </Container>
    );
};

export default ProfileTab;