// hooks/useAuth.js
import { useState, useEffect, useCallback } from 'react';
import { authenticate } from '../auth';

const useAuth = () => {
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null); // Состояние для ошибок

    const initAuth = useCallback(async () => {
        if (process.env.NODE_ENV === 'production') {
            const initData = window.Telegram?.WebApp?.initData || '';
            try {
                const jwtToken = await authenticate(initData);
                setToken(jwtToken);
                window.Telegram.WebApp.ready();
            } catch (error) {
             //   console.error('Authentication failed:', error);
                if (error.response && error.response.status === 401 && error.response.data.result === 'not registered') {
                    setError({
                        title: error.response.data.title || 'Almost done',
                        description: error.response.data.description || 'Complete registration in the game bot using the /start command and select a castle.',
                    });
                } else {
                    setError({
                        title: 'Auth Error',
                        description: error.response?.data?.message || 'Please try again later',
                    });
                }
            }
        } else {
            try {
                const jwtToken = await authenticate('');
                setToken(jwtToken);
            } catch (error) {
                console.error('Mock Authentication failed:', error.response);
                if (error.response && error.response.status === 401 && error.response.data.result === 'not registered') {
                    setError({
                        title: error.response.data.title || 'Almost done',
                        description: error.response.data.description || 'Complete registration in the game bot using the /start command and select a castle.',
                    });
                } else {
                    setError({
                        title: 'Dev Auth Error',
                        description: error.response?.data?.message || 'Please try again later',
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        initAuth();
    }, [initAuth]);
    return { token, error };
};

export default useAuth;