// src/utils/WidgetRenderer.js
import React from 'react';
import SimpleWidget from '../components/widgets/SimpleWidget';
import InventoryWidget from '../components/widgets/InventoryWidget';
import GuildListWidget from '../components/widgets/GuildListWidget';
import GuildSummaryWidget from '../components/widgets/GuildSummaryWidget';

import MapHeaderWidget from '../components/widgets/MapHeaderWidget';
import MapCellsWidget from '../components/widgets/MapCellsWidget';
import MapTradeWidget from '../components/widgets/MapTradeWidget';

// === Новые импорты для профайл-виджетов ===
import AlertWidget from '../components/widgets/AlertWidget';
import PlayerCard from '../components/widgets/PlayerCard';
import StatsWidget from '../components/widgets/StatsWidget';
import QuestDetailsWidget from "../components/widgets/QuestDetailsWidget";
import OpsWidget from "../components/widgets/OpsWidget";

export function renderWidget(widget) {
    const { k, type, value } = widget;

    switch (type) {
        case 'simple': {
            const { title, body } = value || {};
            return (
                <SimpleWidget
                    key={k}
                    title={title || 'Untitled'}
                    body={body || ''}
                />
            );
        }
        case 'inventory': {
            const { title, inventory, filters } = value || {};
            return (
                <InventoryWidget
                    key={k}
                    title={title}
                    inventory={inventory}
                    filters={filters}
                />
            );
        }
        case 'guildSummary': {
            const { avatarUrl, chipLabel, title, body } = value || {};
            return (
                <GuildSummaryWidget
                    key={k}
                    avatarUrl={avatarUrl}
                    chipLabel={chipLabel}
                    title={title}
                    body={body}
                />
            );
        }
        case 'guildList': {
            const { title, cells } = value || {};
            return (
                <GuildListWidget
                    key={k}
                    title={title}
                    cells={cells}
                />
            );
        }

        // Новое: заголовок карты
        case 'mapRegionHeader': {
            const { title, subtitle, icon } = value || {};
            return (
                <MapHeaderWidget
                    key={k}
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                />
            );
        }

        // Новое: ячейки карты
        case 'mapCells': {
            const { cells } = value || {};
            return (
                <MapCellsWidget
                    key={k}
                    cells={cells}
                />
            );
        }

        case 'mapTrade': {
            // Из бэкенда приходит title, marketLabel, auctionLabel
            const { title, marketLabel, auctionLabel } = value || {};
            return (
                <MapTradeWidget
                    key={k}
                    title={title}
                    marketLabel={marketLabel}
                    auctionLabel={auctionLabel}
                />
            );
        }


        case 'questDetails': {
            const {
                title,
                regionCode,
                imageUrl,
                details,
                buttonLabel,
                buttonBlocked,
                autoModeEnabled,
                autoModeBlocked,
                autoModeHidden,
                statusLabel,
            } = value || {};

            return (
                <QuestDetailsWidget
                    key={k}
                    title={title}
                    regionCode={regionCode}
                    imageUrl={imageUrl}
                    details={details}
                    buttonLabel={buttonLabel}
                    buttonBlocked={buttonBlocked}
                    autoModeEnabled={autoModeEnabled}
                    autoModeBlocked={autoModeBlocked}
                    autoModeHidden={autoModeHidden}
                    statusLabel={statusLabel}
                />
            );
        }

        case 'opsData': {
            const {
                iconName,
                text,
                buttonLabel,
                buttonColor,
                actionId,
            } = value || {};

            return (
                <OpsWidget
                    key={k}
                    iconName={iconName}
                    text={text}
                    buttonLabel={buttonLabel}
                    buttonColor={buttonColor}
                    actionId={actionId}
                />
            );
        }

        case 'alert-widget': {
            const {
                title,
                body,
                icon,
                color,
                textColor,
            } = value || {};

            return (
                <AlertWidget
                    key={k}
                    title={title}
                    body={body}
                    icon={icon}
                    color={color}
                    textColor={textColor}
                />
            );
        }

        case 'player-card': {
            const {
                name,
                guild,
                money,
                bank,
                location,
                rank,
                armor,
                nobleDays,
                level,
                expValue,
                expPercent,
            } = value || {};

            return (
                <PlayerCard
                    key={k}
                    name={name}
                    guild={guild}
                    money={money}
                    bank={bank}
                    location={location}
                    rank={rank}
                    armor={armor}
                    nobleDays={nobleDays}
                    level={level}
                    expValue={expValue}
                    expPercent={expPercent}
                />
            );
        }

        case 'stats-widget': {
            const {
                hpValue,
                hpLabelMain,
                hpLabelSecond,
                manaValue,
                manaLabelMain,
                manaLabelSecond,
                staminaValue,
                staminaLabelMain,
                staminaLabelSecond,
                stepsValue,
                stepsLabelMain,
                stepsLabelSecond,
            } = value || {};

            return (
                <StatsWidget
                    key={k}
                    hpValue={hpValue}
                    hpLabelMain={hpLabelMain}
                    hpLabelSecond={hpLabelSecond}
                    manaValue={manaValue}
                    manaLabelMain={manaLabelMain}
                    manaLabelSecond={manaLabelSecond}
                    staminaValue={staminaValue}
                    staminaLabelMain={staminaLabelMain}
                    staminaLabelSecond={staminaLabelSecond}
                    stepsValue={stepsValue}
                    stepsLabelMain={stepsLabelMain}
                    stepsLabelSecond={stepsLabelSecond}
                />
            );
        }

        default:
            return null;
    }
}