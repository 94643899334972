// src/utils/NetworkActivityManager.js

let subscribers = [];

export const subscribeNetworkActivity = (callback) => {
    subscribers.push(callback);
};

export const unsubscribeNetworkActivity = (callback) => {
    subscribers = subscribers.filter((cb) => cb !== callback);
};

export const startNetworkActivity = (tabKey) => {
    subscribers.forEach((cb) => cb(tabKey, true));
};

export const endNetworkActivity = (tabKey) => {
    subscribers.forEach((cb) => cb(tabKey, false));
};