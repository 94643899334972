import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatNumber } from "../utils/markdown";
import ItemView from './ItemView';
import {fetchAuctionLot} from "../services/api"; // <-- Импортируем ваш новый компонент
import { AuthContext } from '../contexts/AuthContext';
import { useSnackbar } from '../contexts/SnackbarContext';
import Button from '@mui/material/Button';
import {Divider} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LOW_TIME_COLOR = '#FFDDDD'; // Цвет, если осталось меньше часа
const DEFAULT_COLOR = '#F9FAFC';  // Обычный цвет

const getBackgroundColor = (time) => {
    const hourMatch = time.match(/(\d+)h/);
    // Если не найдено значение часов или часов 0 – меньше часа
    if (!hourMatch || Number(hourMatch[1]) === 0) {
        return LOW_TIME_COLOR;
    }
    return DEFAULT_COLOR;
};

export default function LotViewDetails({ open, onClose, lot }) {

    const token = useContext(AuthContext);
    const [isProcessing, setIsProcessing] = useState(false);
    const { showSnackbar } = useSnackbar();

    const handleLotGotoClick = () => {
        setIsProcessing(true);
        fetchAuctionLot(token, lot.lotId)
            .then(data => {
                onClose(); // Закрываем диалог
                setIsProcessing(false);
                showSnackbar(data.result, "success");
            })
            .catch(err => {
                console.error("Error on LOT goto:", err);
                setIsProcessing(false);
                const errorMessage = err.result || err.message || "error";
                showSnackbar(errorMessage, "error");
            });
    };

    return (
        <>
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            {/* Заголовок с кнопкой закрытия */}
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Lot #{lot.lotId}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {/* Основное содержимое */}
            <DialogContent>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5, mb:1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'grey.100', p: 1, borderRadius: 1 }}>
                        <Typography variant="caption" color="text.secondary">Seller</Typography>
                        <Typography variant="caption" fontWeight="bold">{lot.seller}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'grey.100', p: 1, borderRadius: 1 }}>
                        <Typography variant="caption" color="text.secondary">Buyer</Typography>
                        <Typography variant="caption" fontWeight="bold">{lot.buyer}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: getBackgroundColor(lot.endDate), p: 1, borderRadius: 1 }}>
                        <Typography variant="caption" color="text.secondary">Time left</Typography>
                        <Typography variant="caption" fontWeight="bold">{lot.endDate}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap:2, mb:2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                    <Box
                        component="img"
                        src="/img/gold.png"
                        alt="Coin"
                        sx={{ width: 16, height: 16, marginRight: 0.5 }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {formatNumber(lot.price)}
                    </Typography>
                    </Box>

                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleLotGotoClick}
                    >
                        LOT
                    </Button>
                </Box>

                <Divider
                    variant="fullWidth"
                    sx={{
                        my: 2,                     // вертикальные отступы
                        borderBottomWidth: 2,      // толщина линии
                        borderColor: 'grey.300'     // цвет линии
                    }}
                />

                {/* Если у лота есть itemCode, показываем информацию о предмете */}
                {lot.itemCode && (
                    <ItemView itemCode={lot.itemCode} />
                )}
            </DialogContent>

            {/* Цена и теги */}
            <DialogActions sx={{ justifyContent: 'space-between', px: 3 }}>

            </DialogActions>
        </Dialog>
            <Backdrop
                open={isProcessing}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}