import React, {useContext} from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RichText from "../RichText";
import {fetchAction} from "../../services/api";
import {AuthContext} from "../../contexts/AuthContext";
import {useSnackbar} from "../../contexts/SnackbarContext";

/**
 * @param {string} iconName - Название иконки (файл), например "house", "mushroom" и т.д.
 * @param {string} text - Текст, который может быть однострочным или двухстрочным.
 * @param {string} buttonLabel - Текст на кнопке.
 * @param {string} buttonColor - Цвет кнопки (hex, rgb, etc.).
 * @param {string} actionId - ID действия, которое будет выполняться по клику на кнопку.
 */
export default function OpsWidget({
                                      iconName = '',
                                      text = '',
                                      buttonLabel = 'Click',
                                      buttonColor = '#1976d2', // По умолчанию синий
                                      actionId = '',
                                  }) {
    const token = useContext(AuthContext);
    const { showSnackbar } = useSnackbar();

    const handleButtonClick = () => {
        fetchAction(token, actionId)
            .then(data => {
                showSnackbar(data.result, "success");
            })
            .catch(err => {
                const errorMessage = err.result || err.message || "error";
                showSnackbar(errorMessage, "error");
            });
    };

    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                boxSizing: 'border-box',
                width: '100%',
            }}
        >
            {/* Левая часть: иконка фиксированного размера */}
            {/* Левая часть: Иконка занимает 10% */}
            <Box
                sx={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CardMedia
                    component="img"
                    image={`/img/${iconName}.png`}
                    alt={iconName}
                    sx={{ width: '100%', height: 'auto' }}
                />
            </Box>

            {/* Центральная часть: текст, растягивается, центрируется по горизонтали */}
            <Box
                sx={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'left',
                    textAlign: 'left',
                    px: 2,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        lineHeight: 1.2,
                        fontSize: '1rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <RichText content={text} />
                </Typography>
            </Box>

            {/* Правая часть: Кнопка занимает 30% */}
            <Box
                sx={{
                    width: '30%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleButtonClick}
                    sx={{
                        backgroundColor: buttonColor,
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: buttonColor,
                        },
                        overflow: 'hidden',
                    }}
                >
                    {buttonLabel}
                </Button>
            </Box>
        </Card>
    );
}