// src/components/widgets/AlertWidget.js
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Иконки MUI, которые потенциально могут использоваться
import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import WaterDropRoundedIcon from '@mui/icons-material/WaterDropRounded';
import OfflineBoltRoundedIcon from '@mui/icons-material/OfflineBoltRounded';
import HikingRoundedIcon from '@mui/icons-material/HikingRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

const iconMapping = {
    MilitaryTechRoundedIcon: <MilitaryTechRoundedIcon />,
    ShieldRoundedIcon: <ShieldRoundedIcon />,
    StarRoundedIcon: <StarRoundedIcon />,
    FavoriteRoundedIcon: <FavoriteRoundedIcon />,
    WaterDropRoundedIcon: <WaterDropRoundedIcon />,
    OfflineBoltRoundedIcon: <OfflineBoltRoundedIcon />,
    HikingRoundedIcon: <HikingRoundedIcon />,
    ReportProblemRoundedIcon: <ReportProblemRoundedIcon />,
    InfoRoundedIcon: <InfoRoundedIcon />,
};

function renderDynamicIcon(iconName, color) {
    const IconComponent = iconMapping[iconName];
    if (!IconComponent) return null;
    return React.cloneElement(IconComponent, {
        fontSize: 'large',
        style: { color },
    });
}

/**
 * AlertWidget
 * @param {string} title      - Заголовок алерта
 * @param {string} body       - Текст/HTML внутри алерта
 * @param {string} icon       - Название иконки (например: "ReportProblemRoundedIcon")
 * @param {string} color      - Цвет фона карточки (alertCardColor)
 * @param {string} textColor  - Цвет текста и иконки (alertCardTextColor)
 */
const AlertWidget = ({ title, body, icon, color, textColor }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Card
            sx={{
                mb: 2,
                borderRadius: 2,
                backgroundColor: color,
                color: 'black',
                boxShadow: 'none',
            }}
        >
            <CardActionArea
                onClick={() => setExpanded(!expanded)}
                sx={{
                    px: { xs: 2, sm: 3 },
                    py: { xs: 1, sm: 2 },
                    display: 'block',
                }}
            >
                {/* Шапка */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* Левая часть: иконка + заголовок */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 2 }}>
                            {renderDynamicIcon(icon, textColor)}
                        </Box>
                        <Typography variant="body1" sx={{ color: 'inherit' }}>
                            {title}
                        </Typography>
                    </Box>

                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Box>

                {/* Контент */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 1 }}>
                        <Typography
                            variant="body2"
                            sx={{ color: 'inherit' }}
                            dangerouslySetInnerHTML={{ __html: body }}
                        />
                    </Box>
                </Collapse>
            </CardActionArea>
        </Card>
    );
};

export default AlertWidget;