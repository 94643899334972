// src/components/AuctionView.jsx
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { fetchActiveLoads } from '../services/api'; // <-- Предположим, что есть такой метод
import LotView from './LotView';                   // <-- компонент для единичного лота

// Небольшой хелпер стилизовать AppBar
const StyledAppBar = styled(AppBar)(({ theme }) => ({
    position: 'relative',
}));

export default function AuctionView({ open, onClose, token }) {
    const [loading, setLoading] = useState(false);
    const [auctionData, setAuctionData] = useState(null);

    useEffect(() => {
        if (open) {
            setLoading(true);
            fetchActiveLoads(token)
                .then((data) => {
                    // ожидаем формат { Title, Subtitle, Lots: [...] }
                    setAuctionData(data);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error fetching lots:', err);
                    setLoading(false);
                });
        }
    }, [open, token]);

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose}>
            <StyledAppBar color="paper" >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Goldhammer & Sons
                    </Typography>
                </Toolbar>
            </StyledAppBar>

            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : auctionData ? (
                <Box sx={{ p: { xs: 2, sm: 3, md: 4 }}}>
                    <Typography variant="body2" sx={{ mb: 4, color: 'text.secondary' }}>
                        {auctionData.subtitle}
                    </Typography>

                    {/* Список лотов */}
                    {auctionData.lots && auctionData.lots.length > 0 ? (
                        auctionData.lots.map((lot, idx) => (
                            <LotView key={idx} lot={lot} />
                        ))
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                            No lots found.
                        </Typography>
                    )}
                </Box>
            ) : null}
        </Dialog>
    );
}