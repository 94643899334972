import React, { useState, useEffect, useContext } from 'react';
import { fetchItemInspect } from '../services/api';
import { AuthContext } from '../contexts/AuthContext';
import Typography from "@mui/material/Typography";

// Простой кэш в памяти: ключ – itemCode, значение – данные предмета
const itemCache = new Map();

export default function GetItemData({ itemCode, children }) {
    const token = useContext(AuthContext);
    const [data, setData] = useState(itemCache.get(itemCode) || null);
    const [loading, setLoading] = useState(!itemCache.has(itemCode));
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token || !itemCode) return;

        // Если данные уже в кэше – сразу используем их
        if (itemCache.has(itemCode)) {
            setData(itemCache.get(itemCode));
            setLoading(false);
            return;
        }

        setLoading(true);
        fetchItemInspect(token, itemCode)
            .then((response) => {
                // Предполагается, что данные приходят в объекте response.item
                const itemData = response.item;
                // Сохраняем в кэш
                itemCache.set(itemCode, itemData);
                setData(itemData);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Server error:', err);
                setError(err);
                setLoading(false);
            });
    }, [token, itemCode]);

    if (loading) {
        return <Typography variant="body2">Loading...</Typography>;
    }

    if (error) {
        return <Typography variant="body2">Error.</Typography>;
    }

    // Используем render prop для передачи данных в дочерний компонент
    return children(data);
}