import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from '../contexts/SnackbarContext';
import RegionCard from "./RegionCard";

import HikingRoundedIcon from '@mui/icons-material/HikingRounded';

import ClipboardCell from "./ClipboardCell";
import {fetchGoto, fetchGotoCaravan} from '../services/api';
import RichText from "./RichText";
import { Table, TableBody, TableRow, TableCell } from '@mui/material';

const RegionDetails = ({
                           open,
                           onClose,
                           title,
                           subtitle,
                           icon,
                           extraInfo,
                           routeInfo,
                           steps,
                           commandValue,
                           canTravel,
                           isLoading,
                           token,
                           pointId,
                           lootstat,
                            canCaravan,
                            caravanCost,
                            caravanDuration,
                       }) => {
    const showSkeleton = isLoading || !title || !subtitle;
    const [isTraveling, setIsTraveling] = useState(false);
    const { showSnackbar } = useSnackbar();

    const handleGotoClick = () => {
        setIsTraveling(true);
        fetchGoto(token, pointId)
            .then(data => {
                showSnackbar(data.result, "success");
                onClose();
                setIsTraveling(false);
            })
            .catch(err => {
                //console.error("Error on goto:", err);
                const errorMessage = err.result || err.message || "error";
                showSnackbar(errorMessage, "error");
                onClose();
                setIsTraveling(false);
            });
    };

    const handleCaravanClick = () => {
        setIsTraveling(true);
        fetchGotoCaravan(token, pointId)
            .then(data => {
                showSnackbar(data.result, "success");
                onClose();
                setIsTraveling(false);
            })
            .catch(err => {
                //console.error("Error on goto:", err);
                const errorMessage = err.result || err.message || "error";
                showSnackbar(errorMessage, "error");
                onClose();
                setIsTraveling(false);
            });
    };


    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
                {showSkeleton ? (
                    // Показываем скелетон на весь экран
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        p: 2
                    }}>
                        <Skeleton variant="rectangular" height={50} />
                        <Skeleton variant="rectangular" height={100} />
                        <Skeleton variant="rectangular" height={100} />
                        <Skeleton variant="rectangular" height={50} />
                    </Box>
                ) : (
                    // Когда данные загружены, показываем контент
                    <Box
                        sx={{
                            bgcolor: 'background.default',
                            overflow: 'visible',
                            py: { xs: 2, sm: 3, md: 4 },
                            px: { xs: 1, sm: 2, md: 4 },
                            boxSizing: 'border-box',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: { xs: 2, sm: 3, md: 4 },
                        }}
                    >
                        <RegionCard titleLabel={title} subtitle={subtitle} imageFileName={icon}></RegionCard>

                        <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none', }}>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    dangerouslySetInnerHTML={{ __html: routeInfo }}
                                />

                                <Button
                                    variant="contained"
                                    size="medium"
                                    color={'secondary'}
                                    onClick={handleGotoClick}
                                    sx={{ alignSelf: 'flex-start' }}
                                    disabled={canTravel === "false"}
                                >
                                    <b>{steps}</b>
                                    <HikingRoundedIcon style={{ fill: 'currentColor', width: '20px', height: '20px', marginLeft: '8px' }} />
                                </Button>
                                {canCaravan === "true" && (
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2">
                                            Caravan services are available in this region.<br/>{caravanDuration} min
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="secondary"
                                            onClick={handleCaravanClick}
                                            sx={{ alignSelf: 'flex-start', mt: 1 }}
                                        >
                                            <b>{caravanCost}</b>
                                                <Box component="img" src="/img/gold.png" alt="Money Icon" sx={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                                        </Button>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>

                        {extraInfo !== "-" && (
                            <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none', }}>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 0,
                                }}>
                                    <Typography
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        <RichText content={extraInfo} />
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}

                        <Card sx={{ p: { xs: 1, sm: 2, md: 4 }, borderRadius: 2 , boxShadow: 'none', }}>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Typography variant="body2" color="text.secondary">
                                    Bot Command
                                </Typography>
                                <ClipboardCell text={commandValue}></ClipboardCell>
                            </CardContent>
                        </Card>


                        {lootstat && Array.isArray(lootstat) && lootstat.length > 0 && (
                            <Card sx={{ p: { xs: 0, sm: 1, md: 4 }, borderRadius: 2, boxShadow: 'none', mb: 2 }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    {lootstat.map((table, tableIdx) => (
                                        <React.Fragment key={tableIdx}>
                                            <Typography variant="body2" sx={{ mb: 1 }}>
                                                {table.title}
                                            </Typography>
                                            <Table size="small" sx={{ mb: tableIdx !== lootstat.length - 1 ? 2 : 0 }}>
                                                <TableBody>
                                                    {table.elems && table.elems.map((elem, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell sx={{ width: '80%', verticalAlign: 'middle' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Box
                                                                        component="img"
                                                                        src={`/img/items/${elem.icon}.png`}
                                                                        alt={elem.name}
                                                                        sx={{
                                                                            height: '1.5em',
                                                                            width: '1.5em',
                                                                            objectFit: 'cover',
                                                                            mr: 1,
                                                                            borderRadius: 1,
                                                                        }}
                                                                    />
                                                                    <Typography variant="caption">
                                                                        <RichText content={elem.name} />
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography variant="caption">
                                                                    <RichText content={elem.value} />
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </React.Fragment>
                                    ))}
                                </CardContent>
                            </Card>
                        )}

                    </Box>
                )}
            </Dialog>

            {/* Бэкдроп с лоадером, показывается пока идет запрос */}
            <Backdrop open={isTraveling} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default RegionDetails;