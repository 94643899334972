import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const IconText = ({ icon, text, iconSize = 20, spacing = 1 }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center', // Центрирование по вертикали
                gap: spacing, // Отступ между иконкой и текстом
            }}
        >
            <Box
                sx={{
                    fontSize: iconSize, // Размер иконки
                    display: 'flex',
                    alignItems: 'center', // Центрирование иконки
                }}
            >
                {icon}
            </Box>
            <Typography
                variant="body2" // Размер текста
                sx={{ lineHeight: 1 }} // Убираем лишние отступы
            >
                {text}
            </Typography>
        </Box>
    );
};

export default IconText;