// contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { token, error } = useAuth();
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogData] = useState({ title: '', description: '' });

    useEffect(() => {
        if (error && error.title && error.description) {
            setDialogData({
                title: error.title,
                description: error.description,
            });
            setOpen(true);
        }
    }, [error]);

    const handleClose = () => {
        setOpen(false);
        // Проверяем доступность объекта Telegram и метода close
        if (window.Telegram && window.Telegram.WebApp && typeof window.Telegram.WebApp.close === 'function') {
            window.Telegram.WebApp.close();
        } else {
            // Стандартный обработчик закрытия окна
            window.close();
            // Альтернативный вариант: перенаправление на другую страницу
            // window.location.href = 'https://yourfallbackurl.com';
        }
    };

    return (
        <AuthContext.Provider value={token}>
            {children}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="auth-error-dialog-title"
                aria-describedby="auth-error-dialog-description"
            >
                <DialogTitle id="auth-error-dialog-title">{dialogData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="auth-error-dialog-description">
                        {dialogData.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    {/* Добавьте дополнительные кнопки по необходимости */}
                </DialogActions>
            </Dialog>
        </AuthContext.Provider>
    );
};