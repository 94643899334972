// src/components/GuildSummaryWidget.jsx
import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import RichText from '../RichText';

function GuildSummaryWidget({ avatarUrl, chipLabel, title, body }) {
    return (
        <Card sx={{ borderRadius: 2, p: 2, mb: 2, boxShadow: 'none'}}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {/* Левая колонка: аватар + чип */}
                <Box
                    sx={{
                        width: '30%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mr: 2,
                    }}
                >
                    <Avatar
                        src={`/img/${avatarUrl || 'guild'}.png`}
                        alt="Guild Avatar"
                        sx={{
                            width: { xs: 50, sm: 100, md: 150 },
                            height: { xs: 50, sm: 100, md: 150 },
                            borderRadius: 2,
                            mb: 2,
                        }}
                    />
                    <Chip
                        label={chipLabel || 'LVL0'}
                        sx={{
                            width: '100%',
                            borderRadius: 2,
                        }}
                    />
                </Box>

                {/* Правая колонка: заголовок + тело */}
                <Box sx={{ width: '70%' }}>
                    <Typography variant="body1" gutterBottom>
                        {title || 'Title'}
                    </Typography>
                    <Typography variant="body2" sx={{ lineHeight: 2 }}>
                        <RichText content={body || ''} />
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
}

export default GuildSummaryWidget;