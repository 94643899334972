// src/components/widgets/PlayerCard.js
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import IconText from '../IconText';
import ProfileCell from '../ProfileCell';
import ProfileImgCell from '../ProfileImgCell';
import ProgressChip from '../ProgressChip';

/**
 * PlayerCard
 * Карточка с аватаром, рангом/армором/днями и базовыми полями (имя, гильдия, деньги, локация).
 *
 * @param {string} name       - Имя игрока
 * @param {string} guild      - Название гильдии
 * @param {string} money      - Количество золота
 * @param {string} bank       - Сумма в банке
 * @param {string} location   - Текущее местоположение
 * @param {string} rank       - Ранг
 * @param {string} armor      - Броня
 * @param {string} nobleDays  - Количество "Noble days"
 * @param {string} level      - Текст "LVL ..."
 * @param {string} expValue   - Текст прогресса, например "40% 40/100"
 * @param {string | number} expPercent - Число (0-100), процент заполнения опыта
 */
const PlayerCard = ({
                        name,
                        guild,
                        money,
                        bank,
                        location,
                        rank,
                        armor,
                        nobleDays,
                        level,
                        expValue,
                        expPercent
                    }) => {
    return (
        <Box
            sx={{
                px: { xs: 1, sm: 2, md: 3 },
                py: { xs: 2, sm: 4, md: 6 },
                height: '100%',
                bgcolor: '#fff',
                borderRadius: 2,
                mb: 2
            }}
        >
            {/* Основной блок с фото и базовыми полями */}
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ height: '100%' }}>
                {/* Левая колонка */}
                <Grid item xs={3} sm={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            backgroundColor: '#F1F2F6',
                            borderRadius: '12px',
                            boxShadow: 0,
                            padding: 2,
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Avatar
                            src="/img/userpic.png"
                            alt="User Avatar"
                            sx={{
                                width: { xs: 50, sm: 100, md: 150 },
                                height: { xs: 50, sm: 100, md: 150 },
                                borderRadius: 2,
                                mb: 2,
                            }}
                        />

                        <IconText
                            icon={<MilitaryTechRoundedIcon />}
                            text={rank}
                        />
                        <IconText
                            icon={<ShieldRoundedIcon />}
                            text={armor}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Chip
                                icon={<StarRoundedIcon />}
                                label={nobleDays}
                                color="secondary"
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </Grid>

                {/* Правая колонка */}
                <Grid item xs={9} sm={8} sx={{ height: '100%' }}>
                    <Stack spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ height: '100%' }}>
                        <ProfileCell title="Name" content={name} />
                        <ProfileCell title="Guild" content={guild} />
                        <ProfileImgCell
                            title="Money"
                            icon1="gold.png"
                            text1={money}
                            icon2="bank.png"
                            text2={bank}
                        />
                        <ProfileCell title="Location" content={location} />
                    </Stack>
                </Grid>
            </Grid>

            <Box sx={{ height: 15 }} />

            {/* Небольшой блок с LVL + прогресс-бар для exp */}
            <Grid
                container
                spacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                    height: { xs: 35, sm: 50 },
                    alignItems: 'center',
                }}
            >
                <Grid
                    item
                    xs={3}
                    sm={4}
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Chip
                        label={level}
                        color="secondary"
                        sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 2,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xs={9}
                    sm={8}
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <ProgressChip
                        value={expPercent}
                        label={expValue}
                        color="#2ED573"
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default PlayerCard;