import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RichText from '../RichText';
import ItemViewDetails from "../ItemViewDetails";

export default function InventoryWidget({
                                            title = 'Inventory',
                                            inventory = [],
                                            filters = [],
                                        }) {
    const [expanded, setExpanded] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null); // новое состояние для выбранного айтема

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    const safeInventory = Array.isArray(inventory) ? inventory : [];

    const displayedItems = !selectedFilter
        ? safeInventory
        : safeInventory.filter((item) => item.category === selectedFilter);

    const handleFilterClick = (filterName) => {
        setSelectedFilter((prev) => (prev === filterName ? null : filterName));
    };

    return (
        <>
            <Card sx={{ borderRadius: 2, mb: 2, boxShadow: 'none' }}>
                <Box
                    onClick={handleToggle}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        px: 2,
                        py: 1,
                        userSelect: 'none',
                        cursor: 'pointer',
                    }}
                >
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        <RichText content={title} />
                    </Typography>

                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            handleToggle();
                        }}
                        size="small"
                        sx={{ ml: 1 }}
                    >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ px: 2, py: 1 }}>
                        {filters.length > 0 && (
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    mb: 2,
                                    flexWrap: 'wrap',
                                    rowGap: 1,
                                }}
                            >
                                {filters.map((filterName) => (
                                    <Chip
                                        key={filterName}
                                        label={filterName}
                                        color={selectedFilter === filterName ? 'info' : 'default'}
                                        clickable
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFilterClick(filterName);
                                        }}
                                    />
                                ))}
                            </Stack>
                        )}

                        {displayedItems.length > 0 ? (
                            displayedItems.map((item, index) => (
                                <Stack
                                    key={index}
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ mb: 1, cursor: 'pointer' }} // курсор для интерактивности
                                    onClick={() => setSelectedItem(item)} // открываем ItemViewDetails при клике
                                >
                                    <Box
                                        component="img"
                                        src={`/img/items/${item.icon || 'unknown'}.png`}
                                        alt={item.icon}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            objectFit: 'contain',
                                            flexShrink: 0,
                                            borderRadius: 1,
                                        }}
                                    />

                                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                        <RichText content={item.name || ''} />
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 'bold',
                                            width: 50,
                                            textAlign: 'right',
                                            flexShrink: 0,
                                        }}
                                    >
                                        {item.qty || '0'}
                                    </Typography>
                                </Stack>
                            ))
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    py: 4,
                                    color: 'text.secondary',
                                }}
                            >
                                <Typography variant="body2">No items found</Typography>
                            </Box>
                        )}
                    </Box>
                </Collapse>
            </Card>

            {/* Диалог с деталями айтема */}
            {selectedItem && (
                <ItemViewDetails
                    open={true}
                    onClose={() => setSelectedItem(null)}
                    item={selectedItem}
                />
            )}
        </>
    );
}