import React, { useLayoutEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MapCell from '../MapCell';

export default function MapCellsWidget({ cells = [] }) {
    const totalCells = cells.length;
    const dimension = Math.sqrt(totalCells);
    const intDimension = Math.round(dimension);

    const scrollContainerRef = useRef(null);
    const [autoScrolled, setAutoScrolled] = React.useState(false);

    // Ищем индекс ячейки, где chipLabel === '📍'
    const pinnedIndex = cells.findIndex((cell) => cell.chipLabel === '📍');

    useLayoutEffect(() => {
        // Если нет ячейки с «📍» — ничего не делаем
        if (pinnedIndex < 0) return;

        // Если уже проскроллили (или хотим скроллить только один раз при первом маунте),
        // то просто выходим:
        if (autoScrolled) return;

        const container = scrollContainerRef.current;
        if (!container) return;

        // Снимем плавный скролл, чтобы ничего не анимировалось
        container.style.scrollBehavior = 'auto';

        // Находим элемент DOM самой «флажковой» ячейки
        const pinnedEl = container.querySelector(`[data-idx="${pinnedIndex}"]`);
        if (!pinnedEl) return;

        // Координаты контейнера и ячейки
        const containerRect = container.getBoundingClientRect();
        const pinnedRect = pinnedEl.getBoundingClientRect();

        // Положение ячейки внутри прокрутки
        const offsetLeft = pinnedEl.offsetLeft;
        const offsetTop = pinnedEl.offsetTop;

        // Стараемся поставить «флажок» в центр видимой области
        const desiredScrollLeft = offsetLeft + pinnedRect.width / 2 - containerRect.width / 2;
        const desiredScrollTop = offsetTop + pinnedRect.height / 2 - containerRect.height / 2;

        // Клампим (не выходим за границы)
        const maxScrollLeft = container.scrollWidth - containerRect.width;
        const maxScrollTop = container.scrollHeight - containerRect.height;

        const finalLeft = Math.min(Math.max(desiredScrollLeft, 0), maxScrollLeft);
        const finalTop = Math.min(Math.max(desiredScrollTop, 0), maxScrollTop);

        // Скроллим «без анимации» (behavior: 'auto')
        container.scrollTo({
            left: finalLeft,
            top: finalTop,
            behavior: 'auto',
        });

        // И по итогу ставим флаг:
        setAutoScrolled(true);

        // Если потом вам захочется, чтобы вручную прокрутка была плавной,
        // верните обратно 'smooth', но уже *после* установки позиции:
        // container.style.scrollBehavior = 'smooth';
    }, [pinnedIndex, cells, autoScrolled]);

    return (
        <Card sx={{ width: '100%', boxShadow: 'none', borderRadius: 2 }}>
            <CardContent sx={{ px: { xs: 0, sm: 1, md: 2 }, py: { xs: 2, sm: 3, md: 4 } }}>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        aspectRatio: '1',
                    }}
                >
                    <Box
                        ref={scrollContainerRef}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                            // Можно оставить нативный scroll snap (если нужно):
                            scrollSnapType: 'both mandatory',
                            scrollSnapStop: 'always',
                            // По умолчанию сделаем 'auto', чтобы и ручной скролл был без плавности:
                            scrollBehavior: 'auto',
                            // Прячем полосы:
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {/* Внутренняя сетка будет масштабироваться, если >7 */}
                        <Box
                            sx={{
                                width: `calc(100% * ${intDimension}/7)`,
                                height: `calc(100% * ${intDimension}/7)`,
                            }}
                        >
                            <Grid container spacing={0} columns={intDimension}>
                                {cells.map((cell, idx) => (
                                    <Grid
                                        key={idx}
                                        item xs={1}
                                        data-idx={idx}
                                        // Можно поменять на 'center', если нужна привязка к центру при snap
                                        sx={{ scrollSnapAlign: 'start' }}
                                    >
                                        <MapCell {...cell} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}