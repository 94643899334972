import React, {useContext, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {NetworkActivityContext} from "../contexts/NetworkActivityContext";
import '../styles/tabAnimations.css';

const MainTabs = ({ children }) => {
    const [value, setValue] = useState(1);
    const { activity } = useContext(NetworkActivityContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box bgcolor="#f5f5f5" sx={{ width: '100%' }}>
            <Tabs
                value={value}
                centered
                onChange={handleChange}
                textColor="inherit"
                indicatorColor="primary"
            >
                <Tab label="Area" className={activity.area ? 'network-active' : ''} />
                <Tab label="Map" className={activity.map ? 'network-active' : ''} />
                <Tab label="Profile" className={activity.profile ? 'network-active' : ''} />
                <Tab label="Guild" className={activity.guild ? 'network-active' : ''} />
            </Tabs>
            <Box sx={{ px: 0, py:2 }}>
                {React.Children.toArray(children)[value]}
            </Box>
        </Box>
    );
};

export default MainTabs;