// src/components/widgets/StatsWidget.js
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import WaterDropRoundedIcon from '@mui/icons-material/WaterDropRounded';
import OfflineBoltRoundedIcon from '@mui/icons-material/OfflineBoltRounded';
import HikingRoundedIcon from '@mui/icons-material/HikingRounded';

import ProgressCell from '../ProgressCell';

/**
 * StatsWidget
 * Четыре прогресс-бара: HP, Mana, Stamina, Steps
 *
 * @param {number}  hpValue        - Процент HP
 * @param {string}  hpLabelMain    - Текст «X / Y» для HP
 * @param {string}  hpLabelSecond  - Текст «X%» для HP
 *
 * @param {number}  manaValue        - Процент Mana
 * @param {string}  manaLabelMain    - «X / Y» для Mana
 * @param {string}  manaLabelSecond  - «X%» для Mana
 *
 * @param {number}  staminaValue        - Процент Stamina
 * @param {string}  staminaLabelMain    - «X / Y» для Stamina
 * @param {string}  staminaLabelSecond  - «X min»
 *
 * @param {number}  stepsValue        - Процент Steps
 * @param {string}  stepsLabelMain    - «X / Y» для Steps
 * @param {string}  stepsLabelSecond  - «X min»
 */
const StatsWidget = ({
                         hpValue,
                         hpLabelMain,
                         hpLabelSecond,

                         manaValue,
                         manaLabelMain,
                         manaLabelSecond,

                         staminaValue,
                         staminaLabelMain,
                         staminaLabelSecond,

                         stepsValue,
                         stepsLabelMain,
                         stepsLabelSecond
                     }) => {
    return (
        <Box
            sx={{
                px: { xs: 1, sm: 2, md: 3 },
                py: { xs: 2, sm: 4, md: 6 },
                height: '100%',
                bgcolor: '#fff',
                borderRadius: 2,
                mb: 2,
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    width: '100%',
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                }}
            >
                {/* HP */}
                <ProgressCell
                    height={20}
                    label={hpLabelSecond}
                    icon={<FavoriteRoundedIcon style={{ color: '#FF4757' }} />}
                    progressProps={{
                        value: hpValue,
                        label: hpLabelMain,
                        color: '#FF4757',
                        textColor: '#000',
                    }}
                    sx={{ width: '100%' }}
                />

                {/* Mana (рендерим только, если не "0 / 0") */}
                {manaLabelMain !== '0 / 0' && (
                    <ProgressCell
                        height={20}
                        label={manaLabelSecond}
                        icon={<WaterDropRoundedIcon style={{ color: '#1E90FF' }} />}
                        progressProps={{
                            value: manaValue,
                            label: manaLabelMain,
                            color: '#1E90FF',
                            textColor: '#000',
                        }}
                        sx={{ width: '100%' }}
                    />
                )}

                {/* Stamina */}
                <ProgressCell
                    height={20}
                    label={staminaLabelSecond}
                    icon={<OfflineBoltRoundedIcon style={{ color: '#fdcb6e' }} />}
                    progressProps={{
                        value: staminaValue,
                        label: staminaLabelMain,
                        color: '#7f8c8d',
                        textColor: '#000',
                        backgroundColor: '#ecf0f1',
                    }}
                    sx={{ width: '100%' }}
                />

                {/* Steps */}
                <ProgressCell
                    height={20}
                    label={stepsLabelSecond}
                    icon={<HikingRoundedIcon style={{ color: '#FF6348' }} />}
                    progressProps={{
                        value: stepsValue,
                        label: stepsLabelMain,
                        color: '#7f8c8d',
                        textColor: '#000',
                        backgroundColor: '#ecf0f1',
                    }}
                    sx={{ width: '100%' }}
                />
            </Stack>
        </Box>
    );
};

export default StatsWidget;