import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: { main: '#4caf50' },
        error: { main: '#e74c3c' },
        info: { main: '#3498db' },
        warning: { main: '#f1c40f' },
        secondary: { main: '#FFA502' },
        grey: {
            100: '#f5f5f5',
            300: '#d0d0d0'
        },
        background: {
            default: '#f5f5f5'
        },
        text: {
            primary: '#000000',
            secondary: '#333333'
        }
    },
    typography: {
        fontFamily: 'Menlo, Consolas, Liberation Mono, monospace',
    },
    spacing: 8, // 1 шаг = 8px
});

export default theme;