import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Импортируем Material Icons
import HikingRoundedIcon from '@mui/icons-material/HikingRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import HourglassFullRoundedIcon from '@mui/icons-material/HourglassFullRounded';
import OfflineBoltRoundedIcon from '@mui/icons-material/OfflineBoltRounded';

// 1. Карта «строка» -> React-компонент иконки
const materialIconsMap = {
    steps: <HikingRoundedIcon fontSize="inherit" />,
    arrowforward: <ArrowForwardRoundedIcon fontSize="inherit" />,
    sleep: <HotelRoundedIcon fontSize="inherit" />,
    people: <PeopleAltRoundedIcon fontSize="inherit" />,
    level: <MilitaryTechRoundedIcon fontSize="inherit" />,
    time :<HourglassFullRoundedIcon fontSize="inherit" />,
    stamina :<OfflineBoltRoundedIcon fontSize="inherit" />,

};

// Если не нашли в materialIconsMap, вставляем PNG
const IconImg = styled('img')(() => ({
    width: '1.5em',
    height: '1.5em',
    verticalAlign: 'middle',
    margin: '0 0.2em',
}));

/**
 * Компонент-обёртка для картинки.
 * Если картинка не загрузилась (onError), возвращает null, чтобы не рендерить «кривой» контент.
 */
function SafeIcon({ iconName }) {
    const [imgError, setImgError] = React.useState(false);
    if (imgError) return null;
    return (
        <IconImg
            src={`/img/${iconName}.png`}
            alt={iconName}
            onError={() => setImgError(true)}
        />
    );
}

export default function RichText({ content = '' }) {
    // Глобальный счётчик для уникальных ключей
    let globalKeyIndex = 0;

    /**
     * Функция, которая в одном проходе обрабатывает:
     * - <br>, <b>, </b>
     * - и (самым последним) <nobr>, </nobr>
     */
    function renderHtmlChunk(text) {
        const result = [];
        let buffer = '';
        let isBold = false;
        let isNobr = false;

        for (let i = 0; i < text.length; i++) {
            // 1) <br> или <br/>
            const brMatch = text.slice(i).match(/^<br\s*\/?>/i);
            if (brMatch) {
                // Сбрасываем накопленное
                if (buffer) {
                    result.push(
                        isNobr ? (
                            <span
                                key={`nobr-${globalKeyIndex++}`}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {isBold ? <strong>{buffer}</strong> : buffer}
                            </span>
                        ) : (
                            isBold
                                ? <strong key={`b-${globalKeyIndex++}`}>{buffer}</strong>
                                : <React.Fragment key={`t-${globalKeyIndex++}`}>{buffer}</React.Fragment>
                        )
                    );
                    buffer = '';
                }
                // Добавляем перенос
                result.push(<br key={`br-${globalKeyIndex++}`} />);
                i += brMatch[0].length - 1;
                continue;
            }

            // 2) <b>
            if (text.slice(i).toLowerCase().startsWith('<b>')) {
                if (buffer) {
                    result.push(
                        isNobr ? (
                            <span
                                key={`nobr-${globalKeyIndex++}`}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {isBold ? <strong>{buffer}</strong> : buffer}
                            </span>
                        ) : (
                            isBold
                                ? <strong key={`b-${globalKeyIndex++}`}>{buffer}</strong>
                                : <React.Fragment key={`t-${globalKeyIndex++}`}>{buffer}</React.Fragment>
                        )
                    );
                    buffer = '';
                }
                isBold = true;
                i += 2; // пропускаем <b>
                continue;
            }

            // 3) </b>
            if (text.slice(i).toLowerCase().startsWith('</b>')) {
                if (buffer) {
                    result.push(
                        isNobr ? (
                            <span
                                key={`nobr-${globalKeyIndex++}`}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <strong>{buffer}</strong>
                            </span>
                        ) : (
                            <strong key={`b-${globalKeyIndex++}`}>{buffer}</strong>
                        )
                    );
                    buffer = '';
                }
                isBold = false;
                i += 3; // пропускаем </b>
                continue;
            }

            // 4) <nobr> (обрабатываем после <b>, <br>, и т.д.)
            if (text.slice(i).toLowerCase().startsWith('<nobr>')) {
                // Сбрасываем накопленное (без nobr)
                if (buffer) {
                    result.push(
                        isBold
                            ? <strong key={`b-${globalKeyIndex++}`}>{buffer}</strong>
                            : <React.Fragment key={`t-${globalKeyIndex++}`}>{buffer}</React.Fragment>
                    );
                    buffer = '';
                }
                isNobr = true;
                i += 5; // пропускаем <nobr>
                continue;
            }

            // 5) </nobr>
            if (text.slice(i).toLowerCase().startsWith('</nobr>')) {
                if (buffer) {
                    // Сбрасываем накопленное как nowrap
                    result.push(
                        <span
                            key={`nobr-${globalKeyIndex++}`}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {isBold ? <strong>{buffer}</strong> : buffer}
                        </span>
                    );
                    buffer = '';
                }
                isNobr = false;
                i += 6; // пропускаем </nobr>
                continue;
            }

            // Обычный символ
            buffer += text[i];
        }

        // Хвост (после цикла)
        if (buffer) {
            if (isNobr) {
                result.push(
                    <span
                        key={`nobr-${globalKeyIndex++}`}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {isBold ? <strong>{buffer}</strong> : buffer}
                    </span>
                );
            } else {
                result.push(
                    isBold
                        ? <strong key={`b-${globalKeyIndex++}`}>{buffer}</strong>
                        : <React.Fragment key={`t-${globalKeyIndex++}`}>{buffer}</React.Fragment>
                );
            }
        }

        return result;
    }

    // --- Ниже идёт основной парсинг фигурных скобок { ... } ---
    const pattern = /\{([^}]+)\}/g;
    const result = [];
    let lastIndex = 0;
    let match;
    let mainKeyIndex = 0; // для вставок иконок

    while ((match = pattern.exec(content)) !== null) {
        // Текст ДО фигурных скобок
        if (match.index > lastIndex) {
            const chunk = content.slice(lastIndex, match.index);
            // Пропускаем chunk через renderHtmlChunk (b, br, nobr, etc.)
            result.push(...renderHtmlChunk(chunk));
        }

        // Само содержимое фигурных скобок
        const iconNameRaw = match[1];
        const iconName = iconNameRaw.toLowerCase().trim();

        // Проверяем в materialIconsMap
        if (materialIconsMap[iconName]) {
            // Рендерим Material Icon
            result.push(
                <Box
                    key={`mat-icon-${mainKeyIndex++}`}
                    component="span"
                    sx={{
                        display: 'inline-flex',
                        fontSize: '1.5em',
                        verticalAlign: 'middle',
                        margin: '0 0.2em',
                    }}
                >
                    {materialIconsMap[iconName]}
                </Box>
            );
        } else {
            // Вместо прямой отрисовки картинки используем SafeIcon
            result.push(
                <SafeIcon
                    key={`img-icon-${mainKeyIndex++}`}
                    iconName={iconName}
                />
            );
        }

        lastIndex = pattern.lastIndex;
    }

    // Хвост (после последней фигурной скобки)
    if (lastIndex < content.length) {
        const chunk = content.slice(lastIndex);
        result.push(...renderHtmlChunk(chunk));
    }

    // Возвращаем весь массив
    return <>{result}</>;
}