import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ProgressChip from './ProgressChip';

function ProgressCell({
                          height = 40, // Высота компонента
                          icon, // Иконка слева
                          label, // Текст справа
                          progressProps = {}, // Пропсы для ProgressChip
                          spacing = {xs: 1, sm: 2, md: 3}, // Отступы между элементами
                      }) {
    // Жёстко заданные пропорции
    const proportions = {
        icon: 8, // 20% ширины для иконки
        progress: 70, // 60% ширины для прогресс-бара
        label: 22, // 20% ширины для текста
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height, // Устанавливаем высоту компонента
                width: '100%', // Прогресс-ячейка занимает всё доступное пространство

                gap: spacing, // Расстояние между элементами
            }}
        >
            {/* Иконка слева */}
            <Box
                sx={{
                    flex: `0 0 ${proportions.icon}%`, // Пропорциональная ширина
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                {icon}
            </Box>

            {/* Прогресс чип */}
            <Box
                sx={{
                    flex: `0 0 ${proportions.progress}%`, // Пропорциональная ширина
                    height: '100%',
                }}
            >
                <ProgressChip {...progressProps} />
            </Box>

            {/* Текст лейбла справа */}
            <Box
                sx={{
                    flex: `0 0 ${proportions.label}%`, // Пропорциональная ширина
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '100%',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {label}
                </Typography>
            </Box>
        </Box>
    );
}

export default ProgressCell;