import React, {useContext, useState} from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import RichText from "../RichText";
import {AuthContext} from "../../contexts/AuthContext";
import {useSnackbar} from "../../contexts/SnackbarContext";
import {fetchAction} from "../../services/api";

export default function QuestDetailsWidget({
                                               title = '',
                                               regionCode = '',
                                               imageUrl = '',
                                               details = [],
                                               buttonLabel = '',
                                               buttonBlocked = false,
                                               autoModeEnabled = false,
                                                autoModeBlocked = false,
                                               autoModeHidden = false,
                                               statusLabel = '',
                                           }) {


    const [localAutoMode, setLocalAutoMode] = useState(autoModeEnabled);
    const [isProcessing, setIsProcessing] = useState(false);
    const { showSnackbar } = useSnackbar();
    const token = useContext(AuthContext);

    const handleSwitchChange = () => {
        if (autoModeBlocked) return;
        setIsProcessing(true);
        fetchAction(token, "landwatch")
            .then(data => {
                showSnackbar(data.result, "success");
                setLocalAutoMode(!localAutoMode);
                setIsProcessing(false);
            })
            .catch(err => {
                const errorMessage = err.result || err.message || "error";
                showSnackbar(errorMessage, "error");
                setIsProcessing(false);
            });
    };


    return (
        <Card
            sx={{
                borderRadius: 2,
                mb: 2,
                boxShadow: 'none',
                overflow: 'visible',
                width: '100%',
            }}
        >
            {/* Заголовок — копия из RegionCard */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    overflow: 'visible',
                }}
            >
                <Box
                    sx={{
                        width: { xs: '75%', sm: '85%', md: '90%' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 2,
                    }}
                >
                    <Typography variant="subtitle2">
                        <RichText content={title || '-'} />
                    </Typography>
                    <Typography variant="subtitle2">
                        <RichText content={regionCode || '-'} />
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: { xs: '25%', sm: '15%', md: '10%' },
                        position: 'relative',
                        height: 'auto',
                        aspectRatio: '1',
                    }}
                >
                    {imageUrl ? (
                        <CardMedia
                            component="img"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 'auto',
                                borderRadius: 1,
                            }}
                            image={`/img/map/${imageUrl}`}
                            alt={title}
                        />
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            sx={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 0,
                                borderRadius: 1,
                            }}
                        />
                    )}
                </Box>
            </Box>

            {/* Дополнительные элементы */}
            <Box sx={{ p: 2 }}>
                {details.map((detail, index) => (
                    <Box key={index} display="flex" alignItems="center" gap={1} mb={1}>
                        <CardMedia
                            component="img"
                            image={`/img/${detail.icon}.png`}
                            alt={detail.icon}
                            sx={{
                                width: 24,
                                height: 24,
                                borderRadius: 1,
                            }}
                        />
                        <Typography variant="body2">
                            <RichText content={detail.text}/>
                        </Typography>
                    </Box>
                ))}

                <Button
                    variant="contained"
                    color="warning"
                    disabled={buttonBlocked}
                    fullWidth
                    sx={{ mt: 1, mb: 1 }}
                >
                    {buttonLabel}
                </Button>

                {!autoModeHidden && (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="body2">Land Watch Mode:</Typography>
                        <Switch
                            disabled={autoModeBlocked || isProcessing}
                            checked={localAutoMode}
                            onChange={handleSwitchChange}
                        />
                    </Box>
                )}

                <Box mt={1}>
                    <Typography variant="body2">
                        Status: <RichText content={statusLabel}/>
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
}