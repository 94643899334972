import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Card,
    CardActionArea,
    CardContent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RichText from './RichText';
import GetItemData from "../containers/GetItemData";

export default function ItemView({ itemCode }) {
    const theme = useTheme();
    // Теперь ItemView не заботится о токене и фетче – этим управляет GetItemData

    // Стили для свёрнутого состояния описания (одна строка, с многоточием)
    const truncatedTextStyles = {
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    // Динамические стили для контейнера текста и иконки (для описания)
    const [expanded, setExpanded] = React.useState(false);
    const containerStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: expanded ? 'flex-start' : 'center',
    };

    return (
        <GetItemData itemCode={itemCode}>
            {(itemData) => (
                <Box sx={{ mb: 1 }}>
                    {/* Верхняя часть: иконка, название и подтип с чипом */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box
                            component="img"
                            src={`/img/items/${itemData.icon}.png`}
                            alt={itemData.name}
                            sx={{
                                width: 48,
                                height: 48,
                                marginRight: 2,
                                objectFit: 'cover',
                                borderRadius: 2,
                            }}
                        />
                        <Box>
                            <Typography variant="body2">
                                <RichText content={itemData.name} />
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                <RichText content={itemData.type} />
                                {/* Если присутствует tagName, выводим чип */}
                                {itemData.tagName && (
                                    <>
                                        {' '}
                                        <Box
                                            component="span"
                                            sx={{
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                                px: 1,
                                                py: 0,
                                                ml: 0.5,
                                                borderRadius: '4px',
                                                backgroundColor: itemData.tagColor || '#000',
                                                color: '#fff',
                                                fontSize: '0.7rem',
                                                lineHeight: 1.2,
                                            }}
                                        >
                                            {itemData.tagName}
                                        </Box>
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Таблица со статистикой, если она есть */}
                    {Array.isArray(itemData.effects) && itemData.effects.length > 0 && (
                        <Table size="small" sx={{ mb: 2 }}>
                            <TableBody>
                                {itemData.effects.map((effect, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell
                                            sx={{
                                                width: '50%',
                                                verticalAlign: 'top',
                                            }}
                                        >
                                            <RichText content={effect.label} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <RichText content={effect.value} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}

                    {/* Описание с возможностью разворачивания/сворачивания */}
                    {itemData.description?.trim() && (
                        <Card
                            variant="outlined"
                            sx={{
                                boxShadow: 'none',
                                backgroundColor: theme.palette.grey[100],
                            }}
                        >
                            <CardActionArea onClick={() => setExpanded((prev) => !prev)}>
                                <CardContent sx={{ p: 1 }}>
                                    <Box sx={containerStyles}>
                                        <Typography
                                            variant="caption"
                                            sx={!expanded ? truncatedTextStyles : undefined}
                                        >
                                            {itemData.description}
                                        </Typography>
                                        <Box sx={{ ml: 1, mt: expanded ? 0.5 : 0 }}>
                                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </Box>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )}
                </Box>
            )}
        </GetItemData>
    );
}