import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ItemView from './ItemView';
import { AuthContext } from '../contexts/AuthContext';
import { useSnackbar } from '../contexts/SnackbarContext';
import { fetchItemUse } from '../services/api';

export default function ItemViewDetails({ open, onClose, item }) {
    const token = useContext(AuthContext);
    const { showSnackbar } = useSnackbar();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleUseClick = () => {
        if (!item || !item.code) return;
        setIsProcessing(true);
        fetchItemUse(token, item.code)
            .then(data => {
                onClose(); // Закрываем диалог
                setIsProcessing(false);
                showSnackbar(data.result, "success");
            })
            .catch(err => {
                onClose(); // Закрываем диалог
                setIsProcessing(false);
                const errorMessage = err.result || err.message || "error";
                showSnackbar(errorMessage, "error");
            });
    };

    // Проверяем, что массив actions существует и не пуст, и содержит "use"
    const hasUseAction =
        Array.isArray(item.actions) && item.actions.length > 0 && item.actions.includes("use");

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    {item.name} ({item.qty})
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {item.code ? (
                        <ItemView itemCode={item.code} />
                    ) : (
                        <Typography color="text.secondary">Error</Typography>
                    )}
                </DialogContent>
                {hasUseAction && (
                    <DialogActions sx={{ justifyContent: 'flex-start', px: 3, mb:2 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleUseClick}
                            disabled={isProcessing}
                        >
                            Use
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            <Backdrop
                open={isProcessing}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}