// src/components/GuildTab.js
import React, { useState, useEffect, useContext } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { AuthContext } from '../contexts/AuthContext';
import { connectSSE, sendTabChange } from '../services/api';

// Импортируем наш общий рендерер
import { renderWidget } from '../utils/WidgetRenderer';

const GuildTab = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [widgets, setWidgets] = useState([]);

    const token = useContext(AuthContext);

    useEffect(() => {
        if (!token) return;

        // Сигналим бэкенду, что открыта вкладка 'guild'
        sendTabChange(token, 'guild');

        setIsLoading(true);

        const upsertWidget = (widgetKey, dataObj) => {
            setWidgets((prev) => {
                const copy = [...prev];
                const idx = copy.findIndex((w) => w.k === widgetKey);

                if (idx === -1) {
                    copy.push({
                        k: widgetKey,
                        type: dataObj.type || 'Simple',
                        value: { ...dataObj },
                    });
                } else {
                    const updatedWidget = { ...copy[idx] };
                    updatedWidget.type = dataObj.type || updatedWidget.type;
                    updatedWidget.value = {
                        ...updatedWidget.value,
                        ...dataObj,
                    };
                    copy[idx] = updatedWidget;
                }
                return copy;
            });
        };

        const updateWidgetContent = (widgetKey, newValue) => {
            setWidgets((prevWidgets) => {
                const idx = prevWidgets.findIndex((w) => w.k === widgetKey);
                if (idx === -1) return prevWidgets;

                const copy = [...prevWidgets];
                copy[idx] = {
                    ...copy[idx],
                    value: {
                        ...copy[idx].value,
                        ...newValue,
                    },
                };
                return copy;
            });
        };

        // Определяем handlers SSE
        const updateHandlers = {
            widgetsInit: (value) => {
                try {
                    const arr = JSON.parse(value);
                    setWidgets(arr);
                    setIsLoading(false);
                } catch (e) {
                    console.error('Ошибка парса widgetsInit:', e);
                }
            },

            widgetUpdate: (rawValue) => {
                try {
                    const obj = JSON.parse(rawValue);
                    const { k, value } = obj;
                    if (k && value) {
                        updateWidgetContent(k, value);
                    }
                } catch (e) {
                    console.error('Ошибка обработки widgetUpdate:', e);
                }
            },

            _widgetDynamic: (widgetKey, rawValue) => {
                try {
                    const dataObj = (typeof rawValue === 'string')
                        ? JSON.parse(rawValue)
                        : rawValue;

                    upsertWidget(widgetKey, dataObj);
                    setIsLoading(false);
                } catch (e) {
                    console.error(`Ошибка парса для виджета ${widgetKey}:`, e);
                }
            },

            guildTitleUI: (value) => {
                console.log('guildTitleUI:', value);
            },
            guildBodyUI: (value) => {
                console.log('guildBodyUI:', value);
            },
        };

        const eventSource = connectSSE(
            token,
            updateHandlers,
            (err) => {
                console.error('Ошибка SSE-соединения:', err);
            },
            'guild'
        );

        return () => {
            eventSource.close();
        };
    }, [token]);

    if (!token) {
        return (
            <Card sx={{ padding: 2 }}>
                <Typography>Not authorized</Typography>
            </Card>
        );
    }

    if (isLoading) {
        return (
            <Card sx={{ padding: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="rectangular" height={100} />
            </Card>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 2, px: { xs: 1, sm: 2, md: 3 } }}>
            {widgets.map((widget) => (
                <Box key={widget.k}>
                    {renderWidget(widget)}
                </Box>
            ))}
        </Container>
    );
};

export default GuildTab;