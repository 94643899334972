// src/components/WidgetTab.js

import React, { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import useSWR from 'swr';

import { AuthContext } from '../contexts/AuthContext';
import { connectSSE, sendTabChange } from '../services/api';
import { renderWidget } from '../utils/WidgetRenderer';

const WidgetTab = ({ tabKey }) => {
    const token = useContext(AuthContext);
    const { data: widgets, mutate } = useSWR(
        token ? tabKey : null,
        async () => {
            // Замените на вызов REST API, если он появится, иначе возвращаем пустой массив.
            return [];
        },
        {
            dedupingInterval: 60000, // Кэш действителен 1 минуту.
            revalidateOnFocus: false,
            revalidateOnMount: true, // добавляем, чтобы ревалидировать при каждом монтировании
        }
    );

    useEffect(() => {
        if (!token) return;

        // Уведомляем сервер, что пользователь открыл вкладку с ключом tabKey.
        sendTabChange(token, tabKey);

        // Функция для добавления или обновления виджета по ключу.
        const upsertWidget = (widgetKey, dataObj) => {
            mutate(prevWidgets => {
                const widgetsArray = prevWidgets ? [...prevWidgets] : [];
                const idx = widgetsArray.findIndex(widget => widget.k === widgetKey);
                if (idx === -1) {
                    // Если виджет с таким ключом отсутствует – добавляем новый.
                    widgetsArray.push({
                        k: widgetKey,
                        type: dataObj.type || 'simple',
                        value: { ...dataObj },
                    });
                } else {
                    // Если виджет найден – обновляем его.
                    const updatedWidget = { ...widgetsArray[idx] };
                    updatedWidget.type = dataObj.type || updatedWidget.type;
                    updatedWidget.value = { ...updatedWidget.value, ...dataObj };
                    widgetsArray[idx] = updatedWidget;
                }
                return widgetsArray;
            }, false);
        };

        // Функция для точечного обновления содержимого виджета.
        const updateWidgetContent = (widgetKey, newValue) => {
            mutate(prevWidgets => {
                if (!prevWidgets) return prevWidgets;
                const widgetsArray = [...prevWidgets];
                const idx = widgetsArray.findIndex(widget => widget.k === widgetKey);
                if (idx !== -1) {
                    widgetsArray[idx] = {
                        ...widgetsArray[idx],
                        value: { ...widgetsArray[idx].value, ...newValue },
                    };
                }
                return widgetsArray;
            }, false);
        };

        // Обработчики событий SSE.
        const updateHandlers = {
            widgetsInit: (raw) => {
                try {
                    const parsedWidgets = JSON.parse(raw);
                    mutate(parsedWidgets, false);
                } catch (e) {
                    console.error(`${tabKey} widgetsInit parse error:`, e);
                }
            },
            widgetUpdate: (raw) => {
                try {
                    const widgetData = JSON.parse(raw);
                    const { k, value } = widgetData;
                    if (k && value) {
                        updateWidgetContent(k, value);
                    }
                } catch (e) {
                    console.error(`${tabKey} widgetUpdate parse error:`, e);
                }
            },
            _widgetDynamic: (widgetKey, rawValue) => {
                try {
                    const dataObj = typeof rawValue === 'string' ? JSON.parse(rawValue) : rawValue;
                    upsertWidget(widgetKey, dataObj);
                } catch (e) {
                    console.error(`${tabKey} dynamic widget parse error for ${widgetKey}:`, e);
                }
            },
        };

        // Подключаем SSE с использованием tabKey.
        const eventSource = connectSSE(
            token,
            updateHandlers,
            (err) => {
                console.error(`${tabKey} SSE error:`, err);
            },
            tabKey
        );

        return () => {
            eventSource.close();
        };
    }, [token, mutate, tabKey]);

    // Если токена нет – показываем скелетон.
    if (!token) {
        return (
            <Card sx={{ padding: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="rectangular" height={100} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="text" height={50} />
            </Card>
        );
    }

    // Если данные ещё загружаются – показываем скелетон.
    if (!widgets || widgets.length === 0) {
        return (
            <Card sx={{ padding: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="rectangular" height={100} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="text" height={50} />
            </Card>
        );
    }

    // Отображаем список виджетов.
    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                py: { xs: 2, sm: 3, md: 4 },
                px: { xs: 1, sm: 2, md: 4 },
                boxSizing: 'border-box',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 2, sm: 3, md: 4 },
            }}
        >
            {widgets && widgets.map((widget) => (
                <Box key={widget.k}>
                    {renderWidget(widget)}
                </Box>
            ))}
        </Box>
    );
};

export default WidgetTab;