// src/services/api.js

import { fetchWithCommonHeaders } from "./fetchHelper";
import { startNetworkActivity, endNetworkActivity } from '../utils/NetworkActivityManager';

export const connectSSE = (token, updateHandlers, onError, activeTab) => {
    // Формируем SSE-подключение
    const eventSource = new EventSource(
        `${process.env.REACT_APP_API_URL}v2/events?token=${encodeURIComponent(token)}&tab=${encodeURIComponent(activeTab)}`
    );

    eventSource.onmessage = (e) => {
        try {
            // Запускаем анимацию для вкладки, поскольку пришло новое сообщение
            startNetworkActivity(activeTab);

            let updates = JSON.parse(e.data);
            if (!Array.isArray(updates)) {
                throw new Error('Invalid data format');
            }

            updates.forEach(({ key, value }) => {

                // 1) Если вкладка - 'guild', обрабатываем ключи вида W\d+
                if (activeTab === 'guild' && /^W\d+$/.test(key)) {
                    if (typeof updateHandlers['_widgetDynamic'] === 'function') {
                        updateHandlers['_widgetDynamic'](key, value);
                    } else {
                        console.error(`No dynamic handler for guild key: ${key}`);
                    }
                }
                // 2) Если вкладка - 'map', обрабатываем ключи вида M\d+
                else if (activeTab === 'map' && /^M\d+$/.test(key)) {
                    if (typeof updateHandlers['_widgetDynamic'] === 'function') {
                        updateHandlers['_widgetDynamic'](key, value);
                    } else {
                        console.error(`No dynamic handler for map key: ${key}`);
                    }
                }

                // 2) Если вкладка - 'map', обрабатываем ключи вида M\d+
                else if (activeTab === 'area' && /^A\d+$/.test(key)) {
                    if (typeof updateHandlers['_widgetDynamic'] === 'function') {
                        updateHandlers['_widgetDynamic'](key, value);
                    } else {
                        console.error(`No dynamic handler for map key: ${key}`);
                    }
                }

                // 2) Если вкладка - 'profile', обрабатываем ключи вида M\d+
                else if (activeTab === 'profile' && /^P\d+$/.test(key)) {
                    if (typeof updateHandlers['_widgetDynamic'] === 'function') {
                        updateHandlers['_widgetDynamic'](key, value);
                    } else {
                        console.error(`No dynamic handler for map key: ${key}`);
                    }
                }
                // 3) Иначе смотрим, есть ли точное совпадение key в updateHandlers
                else if (key in updateHandlers) {
                    updateHandlers[key](value);
                }
                // 4) Если ничего не подошло — игнорируем (или логируем Unknown key)
                else {
                    console.error(`Unknown key: ${key}`);
                }

            });

            // Сбрасываем анимацию через 1 секунду, чтобы дать время воспроизвести эффект
            setTimeout(() => {
                endNetworkActivity(activeTab);
            }, 1000);

        } catch (error) {
            console.error('Error processing SSE message:', error);
            // Можно вывести на экран ошибку или как-то обработать
            document.body.innerHTML = `<h1 style="color: red; text-align: center;">Internal Error</h1>`;
        }
    };

    eventSource.onerror = (err) => {
        console.error('EventSource failed:', err);
        eventSource.close();
        if (onError) onError(err);
    };

    return eventSource;
};

export const sendTabChange = async (token, activeTab) => {
    try {
        // Запускаем индикатор активности для вкладки activeTab
        startNetworkActivity(activeTab);

        const response = await fetchWithCommonHeaders(
            `${process.env.REACT_APP_API_URL}v2/tabchange`,
            {
                method: "POST",
                token, // передаём JWT-токен, он добавится в заголовки автоматически
                body: { activeTab }, // тело запроса
            }
        );
        // Завершаем активность после получения ответа
        endNetworkActivity(activeTab);

        if (!response.ok) {
          //  console.error('Failed to change tab:', response.statusText);
        }
    } catch (error) {
        endNetworkActivity(activeTab);
        //console.error('Error changing tab:', error);
    }
};

// Новый метод для получения данных региона
export const fetchRegionData = async (token, pointId) => {
    const url = `${process.env.REACT_APP_API_URL}v2/region`;

    // Вызываем обёртку, все нужные заголовки добавятся автоматом
    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token, // здесь ваш JWT
        body: { pointId },
    });

    if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
    }

    return response.json();
};

export const fetchItemInspect = async (token, itemCode) => {
    const url = `${process.env.REACT_APP_API_URL}v2/item/inspect`;

    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token,             // ваш JWT-токен
        body: { itemCode } // то, что отправляем на бэкенд
    });

    if (!response.ok) {
        throw new Error("Failed to fetch item data: " + response.statusText);
    }

    return response.json();
};

export const fetchActiveLoads = async (token) => {
    const url = `${process.env.REACT_APP_API_URL}v2/auction/lots`;
    // или любой ваш эндпоинт, который возвращает { Title, Subtitle, LotViewData: [...] }

    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token,
        // если нужно тело: body: { ...somePayload }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch active loads: " + response.statusText);
    }

    return response.json();
};

const fetchGotoHelper = async (token, pointId, endpoint) => {
    const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token,
        body: { pointId },
    });
    if (!response.ok) {
        let errorResult = "error";
        try {
            const data = await response.json();
            if (data && data.result) {
                errorResult = data.result;
            }
        } catch (e) {}
        const err = new Error(errorResult);
        err.response = response;
        err.result = errorResult;
        throw err;
    }
    return response.json();
};

export const fetchGoto = async (token, pointId) => {
    return fetchGotoHelper(token, pointId, 'v2/goto');
};

export const fetchGotoCaravan = async (token, pointId) => {
    return fetchGotoHelper(token, pointId, 'v2/goto/caravan');
};



export const fetchAction = async (token, action) => {
    const url = `${process.env.REACT_APP_API_URL}v2/action`;
    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token,
        body: { action },
    });
    if (!response.ok) {
        let errorResult = "error";
        try {
            const data = await response.json();
            if (data && data.result) {
                errorResult = data.result;
            }
        } catch (e) {}
        const err = new Error(errorResult);
        err.response = response;
        err.result = errorResult;
        throw err;
    }
    return response.json();
};

export const fetchAuctionLot = async (token, lotId) => {
    const url = `${process.env.REACT_APP_API_URL}v2/auction/lot`; // Или, если есть отдельный endpoint, например, /v2/gotoLot

    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token,
        body: { lotId: lotId }, // передаём lot вместо pointId
    });

    if (!response.ok) {
        let errorResult = "error";
        try {
            const data = await response.json();
            if (data && data.result) {
                errorResult = data.result;
            }
        } catch (e) {}
        const err = new Error(errorResult);
        err.response = response;
        err.result = errorResult;
        throw err;
    }

    return response.json();
};


export const fetchItemUse = async (token, itemCode) => {
    const url = `${process.env.REACT_APP_API_URL}v2/item/use`; // Или, если есть отдельный endpoint, например, /v2/gotoLot

    const response = await fetchWithCommonHeaders(url, {
        method: "POST",
        token,
        body: { itemCode: itemCode }, // передаём lot вместо pointId
    });

    if (!response.ok) {
        let errorResult = "error";
        try {
            const data = await response.json();
            if (data && data.result) {
                errorResult = data.result;
            }
        } catch (e) {}
        const err = new Error(errorResult);
        err.response = response;
        err.result = errorResult;
        throw err;
    }

    return response.json();
};

// Уже есть импорты, добавьте только новый метод
export const fetchAdminPanelData = async (token) => {
    const response = await fetchWithCommonHeaders(`${process.env.REACT_APP_API_URL}v2/adminview`, {
        method: 'POST',
        token, // добавляем токен
        credentials: 'include',
      //  body: JSON.stringify({ adminPanelId })
    });

    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
    }

    return response.json();
};