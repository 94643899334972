// src/App.js
import React from 'react';
import MainTabs from './components/MainTabs';
import ProfileTab from './components/ProfileTab';
import MapTab from './components/MapTab';
import GuildTab from './components/GuildTab';
import AreaTab from './components/AreaTab';

import { AuthProvider } from './contexts/AuthContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import {NetworkActivityProvider} from "./contexts/NetworkActivityContext";

function App() {
    return (
        <AuthProvider>
            <SnackbarProvider>
                <NetworkActivityProvider>
                <MainTabs>
                    <AreaTab />
                    <MapTab />
                    <ProfileTab />
                    <GuildTab />
                </MainTabs>
                </NetworkActivityProvider>
            </SnackbarProvider>
        </AuthProvider>
    );
}

export default App;