// src/services/commonHeaders.js

import FingerprintJS from "@fingerprintjs/fingerprintjs";

let cachedData = null;

// Будем хранить initData во внутренней переменной модуля.
// Можно при желании положить в localStorage, если нужно переживать перезагрузку страницы.
let storedInitData = "";

/**
 * Устанавливаем initData (вызывается в auth.js после успешной авторизации).
 * @param {string} initData
 */
export function setInitData(initData) {
    storedInitData = initData;
    // или, если нужно хранить в localStorage:
    // localStorage.setItem("telegram_init_data", initData);
}

/**
 * Получаем ранее сохранённый initData
 * @returns {string}
 */
export function getInitData() {
    // или, если в localStorage:
    // return localStorage.getItem("telegram_init_data") || "";
    return storedInitData;
}

/**
 * Возвращает объект с нужными данными для заголовков:
 *   - userAgent
 *   - telegram platform
 *   - telegram version
 *   - visitorId
 */
export async function getCommonHeadersData() {
    if (cachedData) {
        return cachedData;
    }

    const userAgent = navigator.userAgent || "";
    const platform = window.Telegram?.WebApp?.platform || "";
    const version = window.Telegram?.WebApp?.version || "";

    let visitorId = "";
    try {
        const fp = await FingerprintJS.load();
        const fpResult = await fp.get();
        visitorId = fpResult.visitorId;
    } catch (e) {
        console.warn("Fingerprint failed:", e);
    }

    cachedData = { userAgent, platform, version, visitorId };
    return cachedData;
}