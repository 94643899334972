// src/contexts/NetworkActivityContext.js
import React, { createContext, useState, useEffect } from 'react';
import { subscribeNetworkActivity, unsubscribeNetworkActivity } from '../utils/NetworkActivityManager';

export const NetworkActivityContext = createContext({
    activity: {}, // ключ – tabKey, значение – булево
});

export const NetworkActivityProvider = ({ children }) => {
    const [activity, setActivity] = useState({});

    useEffect(() => {
        const callback = (tabKey, isActive) => {
            setActivity((prev) => ({ ...prev, [tabKey]: isActive }));
        };
        subscribeNetworkActivity(callback);
        return () => {
            unsubscribeNetworkActivity(callback);
        };
    }, []);

    return (
        <NetworkActivityContext.Provider value={{ activity }}>
            {children}
        </NetworkActivityContext.Provider>
    );
};