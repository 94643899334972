// src/services/fetchHelper.js

import { getCommonHeadersData, getInitData } from "./commonHeaders";

/**
 * Универсальная обёртка над fetch, добавляющая "общие" заголовки.
 * @param {string} url - конечная точка API
 * @param {Object} options - настройки запроса (method, body, и т.д.)
 * @param {string} [options.token] - JWT-токен, если нужен
 * @param {Object} [options.extraHeaders] - дополнительные заголовки
 * @returns {Promise<Response>} - объект Response
 */
export async function fetchWithCommonHeaders(url, options = {}) {
    const {
        token,
        method = "GET",
        body,
        extraHeaders = {}
    } = options;

    // Получаем общие данные для заголовков
    const { userAgent, platform, version, visitorId } = await getCommonHeadersData();

    // Формируем заголовки
    const headers = {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        "X-User-Agent": userAgent,
        "X-Telegram-Platform": platform,
        "X-Telegram-Version": version,
        "X-Visitor-Id": visitorId,
        ...extraHeaders,
    };

    const fetchOptions = {
        method,
        headers,
        credentials: "include", // если нужно
    };

    // ==== ВАЖНАЯ ЧАСТЬ: если POST, добавляем initData ====
    let finalBody = body || {};
    if (method.toUpperCase() === "POST") {
        const initData = getInitData();
        // Если у нас есть initData, добавляем его в тело
        if (initData) {
            finalBody = {
                ...finalBody,
                initData, // новое поле
            };
        }
    }

    // Если в finalBody что-то есть — сериализуем
    if (Object.keys(finalBody).length > 0) {
        fetchOptions.body = JSON.stringify(finalBody);
    }

    return fetch(url, fetchOptions);
}