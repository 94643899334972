import React, { useState, useContext } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RichText from '../RichText';
import RegionDetails from '../RegionDetails';
import { fetchRegionData } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const GuildListWidget = ({ title = '', cells = [] }) => {
    const token = useContext(AuthContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [regionData, setRegionData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Флаг «свернуто / развернуто»
    const [expanded, setExpanded] = useState(true);

    // Закрыть диалог
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Клик по locationLink
    const handleLocationClick = (locationLink, locationName) => {
        if (!locationLink) return;
        setOpenDialog(true);
        setRegionData(null);
        setIsLoading(true);

        fetchRegionData(token, locationLink)
            .then((data) => {
                setRegionData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error('Error fetching region data:', err);
                setIsLoading(false);
            });
    };

    // Переключить свёрнуто/развёрнуто
    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Card sx={{ borderRadius: 2, p: 2, mb: 2, boxShadow: 'none' }}>
            {/*
        Вместо CardActionArea оборачиваем только заголовок в Box,
        чтобы не было «кнопка внутри кнопки».
      */}
            <Box
                onClick={handleToggle}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    userSelect: 'none',
                    cursor: 'pointer',    // укажем курсор «pointer», чтобы показать кликабельность
                    mb: 1,                // небольшой отступ под шапкой
                }}
            >
                <Typography variant="body1" sx={{ flexGrow: 1, px: 1 }}>
                    <RichText content={title} />
                </Typography>

                {/* Кнопка-иконка (стрелка), останавливаем всплытие, чтобы не отрабатывал onClick бокса повторно */}
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        handleToggle();
                    }}
                    sx={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s',
                    }}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </Box>

            {/* Содержимое (список ячеек) в Collapse */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Stack spacing={{ xs: 0.5, sm: 1, md: 2 }}>
                    {cells.map((cell, index) => (
                        <Card
                            key={index}
                            sx={{
                                borderRadius: 2,
                                p: 0,
                                boxShadow: 0,
                                bgcolor: '#F1F2F6',
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={{ xs: 1, sm: 2, md: 3 }}
                                alignItems="center"
                                sx={{ p: 0 }}
                            >
                                {/* 1) Аватар */}
                                <Box
                                    sx={{
                                        width: '25%',
                                        aspectRatio: '1/1',
                                        maxHeight: 72,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                    }}
                                >
                                    {cell.avatar ? (
                                        <Box
                                            component="img"
                                            src={`/img/${cell.avatar}.png`}
                                            alt={cell.avatar}
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            No Avatar
                                        </Typography>
                                    )}
                                    {cell.status && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                borderRadius: 1,
                                                bottom: 0,
                                                left: 0,
                                                width: '30%',
                                                height: '30%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography variant="body1">
                                                <RichText content={cell.status || ''} />
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>

                                {/* 2) Лейбл+tags */}
                                <Box sx={{ width: '53%' }}>
                                    <Stack spacing={0.5}>
                                        <Typography variant="body2">
                                            <RichText content={cell.label || ''} />
                                        </Typography>
                                        {cell.tags && cell.tags.length > 0 && (
                                            <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 1.5 }}>
                                                {cell.tags.map((tag, idx) => (
                                                    <Box
                                                        key={idx}
                                                        sx={{
                                                            display: 'inline-block',
                                                            border: '1px solid',
                                                            borderColor: (theme) => theme.palette.secondary.main,
                                                            bgcolor: '#F1F2F6',
                                                            borderRadius: 1.5,
                                                            px: 0.5,
                                                            py: 0,
                                                        }}
                                                    >
                                                        <Typography variant="caption" sx={{ color: 'text.primary' }}>
                                                            <RichText content={tag} />
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        )}
                                    </Stack>
                                </Box>

                                {/* 3) Локация */}
                                <Box sx={{ width: '22%' }}>
                                    {cell.locationLink ? (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleLocationClick(cell.locationLink, cell.location)}
                                            sx={{
                                                display: 'inline-block',
                                                borderRadius: 2,
                                                px: 0,
                                                py: 0.5,
                                            }}
                                        >
                                            <RichText content={cell.location || ''} />
                                        </Button>
                                    ) : (
                                        <Typography variant="caption">
                                            <RichText content={cell.location || ''} />
                                        </Typography>
                                    )}
                                </Box>
                            </Stack>
                        </Card>
                    ))}
                </Stack>
            </Collapse>

            {/* Диалог регионов */}
            <RegionDetails
                open={openDialog}
                onClose={handleCloseDialog}
                title={regionData?.title}
                subtitle={regionData?.subtitle}
                icon={regionData?.icon}
                extraInfo={regionData?.extraInfo}
                routeInfo={regionData?.routeInfo}
                steps={regionData?.steps}
                commandValue={regionData?.commandValue}
                canTravel={regionData?.canTravel}
                isLoading={isLoading}
                token={token}
                pointId={regionData?.pointId}
                lootstat={regionData?.lootstat}
                canCaravan={regionData?.canCaravan}
                caravanCost={regionData?.caravanCost}
                caravanDuration={regionData?.caravanDuration}
            />
        </Card>
    );
};

export default GuildListWidget;