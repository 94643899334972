import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function ProgressChip({ value, label, color = '#4caf50', textColor = '#000', backgroundColor = '#CED6E0' }) {    return (
        <Box
            sx={{
                width: '100%', // Занимает всю ширину родителя
                height: '100%', // Занимает всю высоту родителя
                backgroundColor: backgroundColor, // Фон для незаполненной части
                borderRadius: 2, // Закругление углов
                overflow: 'hidden', // Чтобы прогресс не выходил за границы
                position: 'relative', // Для размещения текста поверх
            }}
        >
            {/* Заполненная часть */}
            <Box
                sx={{
                    width: `${value}%`, // Заполнение в процентах
                    height: '100%',
                    backgroundColor: color, // Цвет заполнения
                }}
            />
            {/* Текст поверх */}
            <Typography
                variant="body2"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Центрирование текста
                    color: textColor, // Цвет текста
                    fontWeight: 'normal',
                    whiteSpace: 'nowrap', // Запрет переноса текста
                }}
            >
                {label}
            </Typography>
        </Box>
    );
}

export default ProgressChip;