import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
// Иконки-«стрелочки»
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import RichText from '../RichText';

function SimpleWidget({ title = 'Untitled', body = '' }) {
    // Локальное состояние, отвечает за «открыто / свернуто»
    const [expanded, setExpanded] = useState(true);

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Card
            sx={{
                borderRadius: 2,
                mb: 2,
                boxShadow: 'none',
            }}
        >
            {/* Вся поверхность карточки — кликабельная */}
            <CardActionArea
                onClick={handleToggle}
                sx={{
                    px: 2,
                    py: 2,
                    display: 'block', // Чтобы содержимое тянулось по всей ширине
                }}
            >
                {/* Шапка (заголовок + иконка) */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="body1" gutterBottom>
                        {title}
                    </Typography>

                    {/* Стрелочка, меняющаяся при сворачивании */}
                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Box>

                {/* Содержимое скрываем/показываем через Collapse */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        <RichText content={body} />
                    </Typography>
                </Collapse>
            </CardActionArea>
        </Card>
    );
}

export default SimpleWidget;