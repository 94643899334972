// src/components/widgets/MapHeaderWidget.jsx
import React from 'react';
import Box from '@mui/material/Box';
import RegionCard from '../RegionCard'; // <-- Ваш уже существующий компонент RegionCard

export default function MapHeaderWidget({
                                            title = '-',
                                            subtitle = '-',
                                            icon = '',
                                        }) {
    return (
        <Box
            sx={{
                // Точно такой же контейнер, как был вокруг RegionCard
                // Если не было отдельного <Box>, можете оставить просто <RegionCard>
                // или прописать нужные стили
            }}
        >
            <RegionCard
                titleLabel={title}
                subtitle={subtitle}
                imageFileName={icon}
            />
        </Box>
    );
}