import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProfileImgCell = ({ title, icon1, text1, icon2, text2 }) => {
    return (
        <Card
            sx={{
                bgcolor: '#F1F2F6', // Цвет фона
                borderRadius: 2, // Закругленные углы
                boxShadow: 0, // Тень

                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: { xs: 1, sm: 2, md: 3 }, // Отступы внутри карточки
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: 'bold', // Жирный текст для заголовка
                    color: 'text.primary', // Основной цвет текста
                }}
            >
                {title}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: { xs: 1, sm: 2, md: 3 }, // Минимальный отступ между блоками
                }}
            >
                {/* Первый блок: Иконка и текст */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: { xs: 0.5, sm: 2, md: 3 }, // Отступ между иконкой и текстом
                    }}
                >
                    <img
                        src={`/img/${icon1}`}
                        alt={text1}
                        style={{ width: 20, height: 20 }}
                    />
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 'normal',
                            color: 'text.secondary',
                            textAlign: 'right',
                        }}
                    >
                        {text1}
                    </Typography>
                </Box>

                {/* Второй блок: Иконка и текст */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: { xs: 0.5, sm: 2, md: 3 }, // Отступ между иконкой и текстом
                    }}
                >
                    <img
                        src={`/img/${icon2}`}
                        alt={text2}
                        style={{ width: 20, height: 20 }}
                    />
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 'normal',
                            color: 'text.secondary',
                            textAlign: 'right',
                        }}
                    >
                        {text2}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

export default ProfileImgCell;